import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Checkbox } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./style.css";
import { useEffect, useRef, useState } from "react";

import { capitalizeFirstLetter } from "../../utils/clearAllSelectedFilters";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";

import { SaveCancel } from "../SaveCancel";
import CustomTimePicker from "../CustomTimePicker";

import { Loader } from "../Loader";
import { getUserAvatar } from "../../utils/TableWithActionColumnFunctions";
import { allWorkingDays, dataToBeSaved } from "../../pages/ChangeShift/data";
import { formatTimeValue } from "../../utils/common_functions";

const getUserWorkingDays = (userData) => {
  if (
    !userData ||
    Object.keys(userData.user_shift).length === 0 ||
    userData?.user_shift.working_days.length === 0
  )
    return [];
  else
    return userData.user_shift.working_days.map((item) =>
      capitalizeFirstLetter(item)
    );
};

const getUserTime = (userData, timeType, setShowTime) => {
  if (
    Object.keys(userData.user_shift).length === 0 ||
    Object.keys(userData.user_shift.working_shift).length === 0 ||
    userData.user_shift.working_shift[timeType]?.length === 0
  ) {
    return "";
  }

  setShowTime(true);
  return userData.user_shift.working_shift[timeType];
};

const ChangeUserShiftPage = ({
  page,
  errorNotifyList,
  fetchUserShiftData,
  updateUserShift,
  isLoading,
}) => {
  const [username, setUsername] = useState("");
  const [userAvatar, setUserAvatar] = useState("");
  const [startTime, setStartTime] = useState("01:00 AM");
  const [endTime, setEndTime] = useState("01:30 AM");

  const [showStartTime, setShowStartTime] = useState(false);
  const [showEndTime, setShowEndTime] = useState(false);

  const [defaultUserData, setDefaultUserData] = useState(null);

  const [saveButtonStatus, setSaveButtonStatus] = useState(false);

  const [checkboxes, setCheckboxes] = useState(allWorkingDays);

  const isFetchCalled = useRef(false);

  const handleGoBack = () => {
    window.location.href = `/users`;
  };

  const handleChange = (id) => {
    setCheckboxes((prevCheckboxes) => {
      const index = prevCheckboxes.findIndex((checkbox) => checkbox.id === id);
      if (index === -1) return prevCheckboxes;

      const updatedCheckboxes = [...prevCheckboxes];
      updatedCheckboxes[index] = {
        ...updatedCheckboxes[index],
        checked: !updatedCheckboxes[index].checked,
      };

      return updatedCheckboxes;
    });

    setSaveButtonStatus(true);
  };

  const handleStartTimeChange = (timeValue) => {
    const updatedTime = formatTimeValue(timeValue);

    setStartTime(updatedTime);

    setSaveButtonStatus(true);
  };

  const handleEndTimeChange = (timeValue) => {
    const updatedTime = formatTimeValue(timeValue);

    setEndTime(updatedTime);

    setSaveButtonStatus(true);
  };

  const getUserStartTime = (userData) =>
    getUserTime(userData, "start_time", setShowStartTime);

  const getUserEndTime = (userData) =>
    getUserTime(userData, "end_time", setShowEndTime);

  const handleRestChangeUserShift = (
    updatedStartTime,
    updatedEndTime,
    data
  ) => {
    setStartTime(updatedStartTime);
    setEndTime(updatedEndTime);
    setUsername(data.first_name);
    setUserAvatar(data.user_avatar);

    setDefaultUserData(data);
  };

  const findSelectedWorkingDays = () => {
    return checkboxes
      .filter((item, index) => item.checked === true)
      .map((item, index) => item.text.toLowerCase());
  };

  const createDataToBeSaved = () => {
    const temp = {};
    temp.new_working_days = findSelectedWorkingDays();
    temp.new_working_shift = {
      start_time: startTime,
      end_time: endTime,
    };

    return temp;
  };

  const handleChangeUserShift = (e) => {
    const dataToBeSaved = createDataToBeSaved();
    if (
      dataToBeSaved.new_working_shift.start_time.length == 0 ||
      dataToBeSaved.new_working_shift.end_time.length == 0
    ) {
      errorNotifyList(["Error: Please specify working hours"]);
      return;
    }

    updateUserShift(dataToBeSaved);
  };

  const handleUserCancel = (e) => {
    const userWorkingDays = getUserWorkingDays(defaultUserData);

    if (userWorkingDays.length === 0) return;

    const tempCheckboxes = checkboxes.map((item) => {
      const checkBoxDay = item.text;
      const isUserWorkingDay = userWorkingDays.includes(checkBoxDay);

      return {
        ...item,
        checked: isUserWorkingDay,
      };
    });

    setCheckboxes(tempCheckboxes);

    const updatedStartTime = getUserStartTime(defaultUserData);
    const updatedEndTime = getUserEndTime(defaultUserData);

    setStartTime(updatedStartTime);
    setEndTime(updatedEndTime);
    window.location.href = `/users`;
  };

  useEffect(() => {
    if (!isFetchCalled.current)
      fetchUserShiftData(
        getUserWorkingDays,
        setCheckboxes,
        getUserStartTime,
        getUserEndTime,
        handleRestChangeUserShift,
        checkboxes
      );
  }, []);

  useEffect(() => {}, [dataToBeSaved]);

  useEffect(() => {}, [userAvatar]);

  return isLoading ? (
    <div className="loaderDiv">
      <Loader loading={isLoading} color={"#8f5ff7"} size={20} />
    </div>
  ) : (
    <Grid
      sx={{
        paddingTop: "2em",
        backgroundColor: "white",
      }}
    >
      <Grid
        className="EdituserHeading"
        sx={{
          fontWeight: "bolder",
        }}
      >
        <Button onClick={handleGoBack}>
          <ArrowBackIcon fontSize="small" />
        </Button>
        {page}
      </Grid>

      <Grid className="mainGrid">
        <Grid sx={{ padding: "0px", color: "#3C3E49" }} size={12} item>
          <Grid
            sx={{
              // paddingLeft:"0.5rem",
              color: "#83889E",
              /* Body/Base/Regular */
              fontFamily: "Rubik,sans-serif",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "1.5rem",
            }}
          >
            User
          </Grid>
          <Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                paddingTop: "0.8rem",
                paddingBottom: "0.8rem",
              }}
            >
              <div
                style={{
                  padding: 0,
                  maxWidth: "60px",
                  marginRight: "0.5rem",
                }}
              >
                {userAvatar === null ? (
                  getUserAvatar(userAvatar, username.split(" ")[0], "red")
                ) : (
                  <img
                    src={userAvatar}
                    alt="avatar"
                    style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "8px",
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                {username}
              </div>
            </div>
          </Grid>
          <Grid size={8} item>
            <div
              style={{
                backgroundColor: "#FFF9E1",
                display: "inline-block",
                color: "#3C3E49",
                /* Body/XS/Regular */
                fontFamily: "Rubik,sans-serif",
                fontSize: "0.90rem",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "1rem",
                letterSpacing: "0.0075rem",
                padding: "0.8rem",
              }}
            >
              Please be careful time should be in UTC &#9432;
            </div>
          </Grid>
          <Grid size={12} className="workingDaysContainer" item>
            <Grid
              className="workingDaysText"
              size={12}
              style={{
                marginTop: "1rem",
                fontWeight: 500,
              }}
              item
            >
              Working days
            </Grid>
            <Grid>
              <div className="weekdaysContainer">
                {checkboxes.map((checkbox) => {
                  return (
                    <div className="weekDayContainer" key={checkbox.text}>
                      <div className="weekDayCheckboxContainer">
                        <Checkbox
                          checked={checkbox.checked}
                          onChange={(e) => handleChange(checkbox.id)}
                          sx={{
                            padding: "0",
                            color: "#C0C3CE",
                            "&.Mui-checked": {
                              color: "#7C4DFF",
                            },
                          }}
                        />
                      </div>
                      <div
                        style={{
                          color: "#3C3E49",
                          /* Body/XS/Regular */
                          fontFamily: "Rubik,sans-serif",
                          fontSize: "0.95rem",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "1rem",
                          letterSpacing: "0.0075rem",
                          paddingBottom: "0.5rem",
                        }}
                      >
                        {checkbox.text + "s"}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Grid>
            <Grid size={12} className="workingHoursMainContainer" item>
              <Grid
                className="workingDaysText"
                size={12}
                style={{
                  marginTop: "1.5rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                }}
                item
              >
                Working hours
              </Grid>
              <Grid>
                <div className="workinghoursContainer">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomTimePicker
                      label="Start time"
                      views={["hours", "minutes"]}
                      value={dayjs(`2024-10-04T${startTime}`)}
                      handleTimePickerChange={(newValue) =>
                        handleStartTimeChange(newValue)
                      }
                      shouldShow={showStartTime}
                      sx={{
                        borderRadius: "20px",
                        marginRight: "20px",
                      }}
                    />

                    <CustomTimePicker
                      label="End time"
                      views={["hours", "minutes"]}
                      value={dayjs(`2024-10-04T${endTime}`)}
                      handleTimePickerChange={(newValue) =>
                        handleEndTimeChange(newValue)
                      }
                      shouldShow={showEndTime}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="footerButtons">
        <Grid className="saveCancelButton">
          <SaveCancel
            handleUserSave={handleChangeUserShift}
            saveButtonStatus={saveButtonStatus}
            handleUserCancel={handleUserCancel}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChangeUserShiftPage;
