import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Arrowright from "..//../assets/Arrow right.png";
import "./style.css";
import { SaveCancel } from "../SaveCancel";
import { Loader } from "../Loader";
import RuleSelectionBox from "../RuleSelectionBox";

const mapTriggerRuleInputs = (inputs) => {
  if (!inputs?.length) {
    return [{ type: "", value: "" }];
  }

  return inputs.map((input) => ({
    type: input.field_type || "",
    value: input.field_name || "",
  }));
};

const getClickupListValues = (actionValues) => {
  if (Array.isArray(actionValues) && actionValues.length > 0) {
    return actionValues.map(
      ({ list_id = "", list_name = "", space_id = "", folder_id = "" }) => ({
        list_id,
        list_name,
        space_id,
        folder_id,
      })
    );
  }
  return [{ list_id: "", list_name: "", space_id: "", folder_id: "" }];
};

const getTriggerRuleValues = (triggerRuleValues) => {
  return Array.isArray(triggerRuleValues) && triggerRuleValues.length > 0
    ? triggerRuleValues
    : [""];
};

const AddEditAutomationPage = ({
  isLoading,
  pageId,
  rulesTableData,
  triggerRulesData,
  actionRulesData,
  spaces,
  folders,
  lists,
  listsWithoutFolder,
  sendRulesData,
  fetchSpaceData,
  fetchFolderData,
  fetchListData,
  fetchListWithoutFolderData,
  handleItemClick,
  openItems,
}) => {
  const [ruleName, setRuleName] = useState("");
  const [triggerRuleName, setTriggerRuleName] = useState("");
  const [triggerRuleInputs, setTriggerRuleInputs] = useState([]);
  const [triggerRuleValues, setTriggerRuleValues] = useState([""]);
  const [actionRuleName, setActionRuleName] = useState("");
  const [clickupListValues, setClickupListValues] = useState([
    {
      list_id: "",
      list_name: "",
      space_id: "",
      folder_id: "",
    },
  ]);
  const [actionRuleValues, setActionRuleValues] = useState([""]);
  const [actionRuleInputs, setActionRuleInputs] = useState([]);
  const [formData, setFormData] = useState({
    title: ruleName,
    trigger_rule_id: triggerRuleName,
    action_rule_id: actionRuleName,
  });
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);

  const handleRuleName = (event) => {
    setRuleName(event.target.value);

    setFormData({
      ...formData,
      title: event.target.value,
    });

    setSaveButtonStatus(true);
  };

  const handleTriggerRuleChange = (event) => {
    const selectedTriggerRule = event.target.value;
    setTriggerRuleName(selectedTriggerRule);

    const triggerItem = triggerRulesData.find(
      (item) => item.id == selectedTriggerRule
    );

    const updatedTriggerValue = triggerItem.inputs.map((input) => ({
      type: input.field_type,
      value: input.field_name,
    }));

    setTriggerRuleInputs(updatedTriggerValue);
    setFormData({
      ...formData,
      trigger_rule_id: selectedTriggerRule,
    });
  };

  const handleActionRuleChange = (event) => {
    const selectedActionRule = event.target.value;
    setActionRuleName(selectedActionRule);

    const actionItem = actionRulesData.find(
      (item) => item.id == selectedActionRule
    );

    const updatedActionValue = actionItem.inputs.map((input) => ({
      type: input.field_type,
      value: input.field_name,
    }));

    setActionRuleInputs(updatedActionValue);
    setFormData({
      ...formData,
      action_rule_id: selectedActionRule,
    });
  };

  const handleTriggerValueChange = (event, index) => {
    const updatedValue = event.target.value;

    setTriggerRuleValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = updatedValue;
      return newValues;
    });

    setFormData((prevFormData) => {
      const existingValues = rulesTableData?.trigger_values || [];
      const updatedTriggerValues = [...existingValues];
      updatedTriggerValues[index] = updatedValue;

      return {
        ...prevFormData,
        trigger_values: updatedTriggerValues,
      };
    });

    setSaveButtonStatus(true);
  };

  const updateFormData = (updatedValues, index) => {
    setFormData((prevFormData) => {
      const updatedActionValues = [...(rulesTableData?.action_values || [])];
      updatedActionValues[index] = updatedValues;

      return {
        ...prevFormData,
        action_values: updatedActionValues,
      };
    });
  };

  const handleActionValueChange = (event, index) => {
    const newValue = event.target.value;

    setActionRuleValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = newValue;
      return newValues;
    });

    updateFormData(newValue, index);

    setSaveButtonStatus(true);
  };

  const handleClickupList = (list, ids, index) => {
    const { id, name } = list;
    const { spaceId, folderId } = ids;

    setClickupListValues((prevValues) => {
      const safePrevValues = Array.isArray(prevValues) ? prevValues : [];
      const newValues = [...safePrevValues];

      newValues[index] = {
        list_id: id,
        list_name: name,
        space_id: spaceId,
        folder_id: folderId,
      };

      return newValues;
    });

    updateFormData(
      { list_id: id, list_name: name, space_id: spaceId, folder_id: folderId },
      index
    );

    setSaveButtonStatus(true);
  };

  const handleGoBack = () => {
    window.location.href = `/automations`;
  };

  const sendFormData = () => {
    sendRulesData(formData);
    setSaveButtonStatus(false);
  };

  const clickupProps = {
    spaces,
    folders,
    lists,
    listsWithoutFolder,
    fetchSpaceData,
    fetchFolderData,
    fetchListData,
    fetchListWithoutFolderData,
    handleItemClick,
    openItems,
  };

  useEffect(() => {
    if (rulesTableData.length != 0) {
      setFormData({
        title: rulesTableData.title,
        trigger_rule_id: rulesTableData.trigger_rule?.id,
        action_rule_id: rulesTableData.action_rule?.id,
      });
      setRuleName(rulesTableData.title);
      setTriggerRuleName(rulesTableData.trigger_rule?.id);
      setTriggerRuleInputs(
        mapTriggerRuleInputs(rulesTableData.trigger_rule?.inputs)
      );
      setTriggerRuleValues(
        getTriggerRuleValues(rulesTableData?.trigger_values)
      );
      setActionRuleName(rulesTableData.action_rule?.id);
      setActionRuleInputs(
        mapTriggerRuleInputs(rulesTableData.action_rule?.inputs)
      );
      setActionRuleValues(rulesTableData.action_values || [""]);
      setClickupListValues(getClickupListValues(rulesTableData?.action_values));
    }
  }, [rulesTableData, isLoading]);

  return Object.keys(rulesTableData)?.length == 0 ? (
    <div className="loaderDiv">
      <Loader
        loading={Object.keys(rulesTableData).length == 0}
        color={"#8f5ff7"}
        size={20}
      />
    </div>
  ) : (
    <Grid
      sx={{
        paddingTop: "2rem",
        backgroundColor: "white",
        width: "100%",
      }}
    >
      <Grid className="EditHeading">
        <Button
          onClick={handleGoBack}
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ArrowBackIcon fontSize="small" />
        </Button>
        {pageId ? `Edit Automation - ${ruleName}` : " Add Automation"}
      </Grid>
      <Grid className="mainGrid-automation">
        <Grid sx={{ padding: "16px 0px", color: "#3C3E49", fontSize: "14px" }}>
          <Grid>
            Name this rule<span className="mandatoryAsterik">*</span>
          </Grid>
          <Grid sx={{ textAlign: "end", width: "40%" }}>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={ruleName || ""}
                onChange={handleRuleName}
                sx={{
                  width: "100%",
                  padding: " 0.8rem 0rem 0rem 0rem",
                  "& .MuiOutlinedInput-root": {
                    maxHeight: "36px !important",
                    fontSize: "14px",
                    color: "#3c3e49",
                  },
                }}
                type="text"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        sx={{
          borderColor: "#ECEDF0",
        }}
      />
      <Grid
        container
        sx={{
          height: "80%",
          padding: "3em 4em",
        }}
      >
        <Grid
          item
          size={6}
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              minWidth: 275,
              border: "1px solid #ECEDF0",
              borderRadius: "4px",
              padding: "1rem",
              width: "80%",
            }}
          >
            <Typography variant="h6" component="div">
              When
            </Typography>
            <Typography sx={{ color: "text.secondary", fontSize: "14px" }}>
              This happens
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            sx={{
              position: "absolute",
              borderColor: "#ECEDF0",
              borderWidth: "1px",
              height: "3rem",
              left: "40%",
            }}
          />
          <RuleSelectionBox
            ruleName={triggerRuleName}
            rulesData={triggerRulesData}
            inputsData={triggerRuleInputs}
            valuesData={triggerRuleValues}
            handleChange={handleTriggerRuleChange}
            handleValueChange={handleTriggerValueChange}
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              border: "1px solid #ECEDF0",
              borderRadius: "4px",
              width: "4rem",
              height: "3rem",
              position: "absolute",
              backgroundColor: "#fff",
              textAlign: "center",
            }}
          >
            <img
              src={Arrowright}
              alt="arrow"
              style={{
                width: "2rem",
                paddingTop: "0.4rem",
              }}
            />
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{
              borderColor: "#ECEDF0",
              borderWidth: "1px",
            }}
          />
        </Grid>
        <Grid
          item
          size={5.9}
          sx={{
            display: "flex",
            alignItems: "end",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Box
            sx={{
              minWidth: 275,
              border: "1px solid #ECEDF0",
              borderRadius: "4px",
              padding: "1rem",
              width: "80%",
            }}
          >
            <Typography variant="h6" component="div">
              Then
            </Typography>
            <Typography sx={{ color: "text.secondary", fontSize: "14px" }}>
              Do this action
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            sx={{
              position: "absolute",
              borderColor: "#ECEDF0",
              borderWidth: "1px",
              height: "3rem",
              right: "40%",
              "@media (min-height: 601px) and (max-height: 800px)": {
                top: "17.5%",
              },
              "@media (min-height: 801px)": {
                top: "13.5%",
              },
            }}
          />
          <RuleSelectionBox
            ruleName={actionRuleName}
            rulesData={actionRulesData}
            inputsData={actionRuleInputs}
            valuesData={
              actionRuleInputs[0]?.type == "clickup"
                ? clickupListValues
                : actionRuleValues
            }
            handleChange={handleActionRuleChange}
            handleValueChange={handleActionValueChange}
            clickupProps={clickupProps}
            handleClickupList={handleClickupList}
          />
        </Grid>
      </Grid>
      <Grid className="footerButtons">
        <Grid className="saveCancelButton">
          <SaveCancel
            handleUserSave={sendFormData}
            saveButtonStatus={saveButtonStatus}
            handleUserCancel={handleGoBack}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddEditAutomationPage;
