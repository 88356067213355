import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid2";
import Tooltip from "@mui/material/Tooltip";
import Radio from "@mui/material/Radio";
import { SubTeamTable } from "../SubTeamEditableTable";
import Autocomplete from "@mui/material/Autocomplete";
import { tooltip_message } from "../../utils/constants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";

const SubTeamTableAndText = ({
  enableSubTeam,
  edit_permission,
  setSubTeamcustomField,
  setDataForPutApi,
  subTeamcustomField,
  dataForPutApi,
  subteamsList,
  handleUpdateOrCreateSubTeam,
  page,
  deactivateSubTeam,
  customFieldDropdownValues,
  isLoading,
  access_type,
  spaces,
  folders,
  lists,
  listsWithoutFolder,
  fetchSpaceData,
  fetchFolderData,
  fetchListData,
  fetchListWithoutFolderData,
  handleItemClick,
  openItems,
  customID,
  setClickupList,
}) => {
  const [dataFromTable, setDataFromTable] = useState([]);

  useEffect(() => {
    setDataFromTable(subteamsList);
  }, [subteamsList?.length]);

  const disabledParentDropdown = (value) => {
    setDataFromTable(value);
  };
  return (
    enableSubTeam && (
      <>
        {/* sub team custom field id */}
        <Grid sx={{ color: "#3C3E49", fontSize: "14px" }} container>
          <Grid size={10} item sx={{ lineHeight: "5rem" }}>
            Sub team custom field
            <Tooltip title={tooltip_message.sub_team_custom_field}>
              <InfoOutlinedIcon
                sx={{ margin: "1rem 0rem 0rem 0.1rem", fontSize: "14px" }}
              />
            </Tooltip>
          </Grid>
          <Grid
            size={2}
            item
            sx={{
              alignContent: "center",
            }}
          >
            <Autocomplete
              className="subTeamInputBox"
              disablePortal={true}
              disableClearable
              id="combo-box-demo"
              options={customFieldDropdownValues}
              getOptionLabel={(options) => options.name}
              disabled={dataFromTable.length > 0}
              value={subTeamcustomField}
              sx={{
                width: "100%",
                "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                  fontSize: "14px",
                },

                "& .MuiAutocomplete-popper": {
                  backgroundColor: "red",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
              renderOption={(props, option) => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  sx={{
                    padding: "5px 5px",
                    whiteSpace: "unset",
                    wordBreak: "break-all",
                  }}
                  onClick={() => {
                    setSubTeamcustomField(option);
                    setDataForPutApi({
                      ...dataForPutApi,
                      sub_team_custom_field: option.id,
                    });
                  }}
                >
                  <Radio
                    size="small"
                    checked={option.name == subTeamcustomField.name}
                    sx={{
                      color: "#C0C3CE",
                      "&.Mui-checked": {
                        color: "#7C4DFF",
                      },
                    }}
                  />
                  <ListItemText
                    disableTypography
                    primary={option.name}
                    sx={{ fontSize: "13px", color: "#3C3E49" }}
                  />
                </MenuItem>
              )}
            />
          </Grid>
        </Grid>{" "}
        {/*sub team list table */}
        <Grid container className="subTeamTableGrid">
          {" "}
          {subTeamcustomField.id != "" && (
            <>
              <Grid className="sectionHeading">Sub team list</Grid>
              <Grid sx={{ paddingTop: "1rem" }}>
                {" "}
                <SubTeamTable
                  subTeams={subteamsList}
                  updateOrCreateSubTeam={handleUpdateOrCreateSubTeam}
                  page={page}
                  deactivateSubTeam={deactivateSubTeam}
                  isLoading={isLoading}
                  subTeamcustomField={subTeamcustomField}
                  edit_permission={edit_permission}
                  disabledParentDropdown={disabledParentDropdown}
                  dataForPutApi={dataForPutApi}
                  access_type={access_type}
                  spaces={spaces}
                  folders={folders}
                  lists={lists}
                  listsWithoutFolder={listsWithoutFolder}
                  fetchSpaceData={fetchSpaceData}
                  fetchFolderData={fetchFolderData}
                  fetchListData={fetchListData}
                  fetchListWithoutFolderData={fetchListWithoutFolderData}
                  handleItemClick={handleItemClick}
                  openItems={openItems}
                  customID={customID}
                  setClickupList={setClickupList}
                />
              </Grid>{" "}
            </>
          )}
        </Grid>
      </>
    )
  );
};

export default SubTeamTableAndText;
