import { useState } from "react";
import { capitalizeFirstLetter } from "./clearAllSelectedFilters";
import { ChipWithMenu } from "../components/TeamsCountChips";
import "react-multi-date-picker/styles/colors/purple.css";

import DateRangeIcon from "@mui/icons-material/DateRange";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { SimpleTable } from "../components/SimpleTable";

const userCalendarIconColor = (row) => {
  if (
    (row.current_shift.length == 0) &
    (row.upcoming_shift.length == 0) &
    row.shift_exists
  )
    return "calendarButtonMenu_grey";
  else
    return row.current_shift.length > 0
      ? "calendarButtonMenu_green"
      : "calendarButtonMenu_purple";
};

const CalendarButton = ({ rotaShifts }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const columns = [
    { id: "title", label: "Name" },
    { id: "start_time", label: "Start time" },
    { id: "end_time", label: "End time" },
    {
      id: "shift_type_mapping_name",
      label: "Team | Squad",
    },
  ];
  return (
    <div>
        <>
          <IconButton
            {...(rotaShifts.current_shift.length !== 0 ||
            rotaShifts.upcoming_shift.length !== 0 
              ? {onClick: (e) => handleClick(e) }
              : "")}
            className={userCalendarIconColor(rotaShifts)}
            size="small"
          >
            <DateRangeIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            style={{
              maxHeight: "15rem",
              scrollbarWidth: "none",
            }}
          >
            <Grid
              sx={{
                overflow: "-moz-hidden-unscrollable",
              }}
            >
              {rotaShifts.current_shift.length > 0 ? (
                <>
                  <Typography className="shiftHeadingText">
                    Active rota shifts
                  </Typography>
                  <SimpleTable
                    tableContainerStyle={{
                      maxHeight: "10rem",
                      scrollbarWidth: "none",
                      padding: "6px",
                    }}
                    tablesStyle={{
                      width: "27rem",
                      maxHeight: "10rem",

                      "& .MuiTableCell-root": {
                        border: "0.1px solid rgba(224, 224, 224, 1)",
                      },
                    }}
                    rows={rotaShifts.current_shift}
                    columns={columns}
                    headerStyle={{
                      fontSize: "0.6rem",
                      height: "0.5rem",
                      padding: "0px 6px",
                      backgroundColor: "#f1f0f0",
                      // fontWeight:"bold"
                    }}
                    rowStyle={{
                      fontSize: "0.6rem",
                      padding: "0px 6px",
                      height: "1.5rem",
                    }}
                    size={"small"}
                  />
                </>
              ) : (
                <></>
              )}

              {rotaShifts.upcoming_shift.length > 0 ? (
                <>
                  <Typography className="shiftHeadingText">
                    Upcoming rota shifts
                  </Typography>
                  <SimpleTable
                    tableContainerStyle={{
                      maxHeight: "10rem",
                      scrollbarWidth: "none",
                      padding: "6px",
                    }}
                    tablesStyle={{
                      width: "27rem",
                      maxHeight: "10rem",
                      "& .MuiTableCell-root": {
                        border: "0.1px solid rgba(224, 224, 224, 1)",
                      },
                    }}
                    rows={rotaShifts.upcoming_shift}
                    columns={columns}
                    headerStyle={{
                      fontSize: "0.6rem",
                      height: "0.5rem",
                      padding: "0px 6px",
                      backgroundColor: "#f1f0f0",
                    }}
                    rowStyle={{
                      fontSize: "0.6rem",
                      padding: "0px 6px",
                      height: "1.5rem",
                    }}
                    size={"small"}
                  />
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Popover>{" "}
        </>

    </div>
  );
};

export const UsersAvailabilityCell = ({ row }) => {
  const availability = row.available ? "Active" : "Inactive";
  const circleColor = row.available ? "#008243" : "#595959";
  const rotaShifts = row.rota_availability;
  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
      }}
      container
    >
      <Grid item container className={`users${availability}`} size={6}>
        <Grid item style={{ marginRight: "0.5rem" }}>
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="4" cy="4" r="4" fill={circleColor} />
          </svg>
        </Grid>
        {availability}
      </Grid>

      <Grid item size={4}>
        {rotaShifts.current_shift.length > 0 ||
        rotaShifts.upcoming_shift.length > 0 ||
        rotaShifts.shift_exists ? (
          <CalendarButton rotaShifts={rotaShifts} />
        ) : (
          <Grid item size={4}></Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const getUserAvatar = (avatar, firstname, colorCode) => {
  if (avatar)
    return (
      <img
        src={`${avatar}`}
        alt="avatar"
        referrerPolicy={"no-referrer"}
        style={{
          height: "2rem",
          width: "2rem",
          borderRadius: "8px",
        }}
      />
    );
  else {
    const firstLetter = firstname.charAt(0).toUpperCase();
    return (
      <div
        style={{
          width: "2rem",
          height: "2rem",
          backgroundColor: `${colorCode}`,
          color: "white",
          borderRadius: "8px",
          textAlign: "center",
          lineHeight: "2rem",
        }}
      >
        {firstLetter}
      </div>
    );
  }
};

export const headerWidth = (label) => {
  if (label === "Actions") {
    return "6vw";
  } else if (label === "Username") {
    return "18vw";
  }
};

export const checkUserShift = (shift) => {
  if (
    Object.keys(shift).length === 0 ||
    !("working_days" in shift) ||
    shift.working_days.length === 0
  )
    return "N/A";

  return shift.working_days
    .map((item, index) => capitalizeFirstLetter(item).slice(0, 3))
    .join(" ");
};

export const getRemainingTeamsCount = (count, teamList) => {
  if (count <= 0) return null;

  return (
    <div>
      <ChipWithMenu count={count} menuItemList={teamList} />
    </div>
  );
};

export const getSearchParamFromURL = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get("search") || "";
};

export const initializeSearch = (
  getSearchParamFromURL,
  setSearchText,
  setHasUserSearched,
  setDebouncedValue
) => {
  const initialSearch = getSearchParamFromURL();
  if (initialSearch) {
    setSearchText(initialSearch);
    setHasUserSearched(true);
    setDebouncedValue(initialSearch);
  }
};

export const calendarButtonTextAndStyle = (row) => {
  let buttonDesign = "";
  let optionText = "";
  let iconColor = "";

  if (!row.enable_shift && row.calendar_url == "") {
    buttonDesign = "calendarButtonMenu_white";
    optionText = "Create ROTA";
    iconColor = "rgba(0,0,0,0.5)";
  } else if (!row.enable_shift && row.calendar_url != "") {
    buttonDesign = "calendarButtonMenu_purple";
    optionText = "Enable ROTA";
    iconColor = "rgba(179, 136, 255)";
  } else {
    buttonDesign = "calendarButtonMenu_green";
    optionText = "Disable ROTA";
    iconColor = "rgba(105, 240, 174)";
  }
  return {
    buttonDesign: buttonDesign,
    optionList: [optionText],
    iconColor: iconColor,
  };
};
